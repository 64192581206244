<script setup lang="ts">
import type { SubmitEventPromise } from 'vuetify';

import { toast } from 'vue-sonner';
import { debounce } from 'perfect-debounce';

const t = useI18n().t;
const auth = useAuth();

useHead({ title: t('login.title') });

const passRef = ref<{ focus: () => void }>();
const emailExists = ref(false);
const checkingPass = ref(false);

const uname = ref('');
const password = ref('');
const prevUname = ref('');
const submitting = ref(false);

const hidePass = computed(() => !auth.isWIFI && !emailExists.value);
const mainColor = computed(() => auth.uiConfig.primaryColor || 'secondary');

const checkUname = debounce(async (username: string) => {
  try {
    username = username.replace(/^\+/, '');
    if (username.length < 8 || prevUname.value === username) return true;
    if (isNaN(+username) && !username.includes('@')) return true;

    if (!uname.value.length) {
      emailExists.value = false;
      prevUname.value = '';
      return true;
    }

    checkingPass.value = true;
    const { exist } = await auth.check(username);

    prevUname.value = username;
    emailExists.value = exist;

    if (!exist) return t('auth.emailOrPhoneNotFound');
    if (username.includes('@')) sessionStorage.setItem('email', username);
    passRef.value?.focus();
    return true;
  } catch (e) {
    console.error(e);
    return t('errors.genericWithRetry');
  } finally {
    checkingPass.value = false;
  }
}, 800);
const checkUnameEmpty = (u: string): true => {
  if (u.length > 0) return true;
  prevUname.value = '';
  emailExists.value = false;
  return true;
};

async function handleSubmit(e: SubmitEventPromise) {
  submitting.value = true;
  const { valid } = await e;
  if (!valid) return (submitting.value = false);

  const login = await auth.login(uname.value, password.value);
  if (login.error) {
    submitting.value = false;
    return toast.error(login.error.detail);
  }

  const authorize = await auth.authorizeClient(uname.value, password.value);
  if (!authorize.error) {
    auth.redirectUser();
    useCookie('client-data').value = null;
  } else {
    submitting.value = false;
    toast.error(authorize.error.detail);
  }
}

onMounted(() => {
  if (auth.client?.mapping) setTimeout(() => toast.info(t('mapping.loginFirst')), 1000);
});
</script>

<template>
  <v-form class="auth-form" validate-on="lazy input" @submit.prevent="handleSubmit">
    <div>
      <AuthCardTitle class="mb-4">{{ $t('login.title') }}</AuthCardTitle>
      <v-text-field
        name="username"
        v-model="uname"
        :color="mainColor"
        :rules="[checkUnameEmpty, checkUname]"
        :label="$t('auth.emailOrPhone')">
        <template v-if="checkingPass" v-slot:append-inner>
          <v-fade-transition leave-absolute>
            <v-progress-circular indeterminate :color="mainColor" size="24" />
          </v-fade-transition>
        </template>
      </v-text-field>
      <UiPasswordInput
        ref="passRef"
        name="password"
        v-model="password"
        :color="mainColor"
        :label="$t('auth.password')"
        :hidden="hidePass"
        :rules="[(v) => !!v || $t('login.noEmptyPassword')]" />
      <div v-if="!hidePass" class="text-subtitle-2 text-center mb-4">
        <NuxtLink tabindex="-1" to="/authorize/form/reset-pass">{{ $t('login.forgotPassword') }}</NuxtLink>
      </div>
      <AuthOAuthButtons
        v-else
        context="login"
        v-model:loading="submitting"
        :title="$t('login.orLoginWith')" />
    </div>

    <div :class="{ 'mt-2': hidePass }" class="d-flex flex-column ga-4">
      <div class="text-subtitle-2 text-center">
        {{ $t('login.dontHaveAccount') }}
        <NuxtLink tabindex="-1" to="/authorize/form/register">{{ $t('register.title') }}</NuxtLink>
      </div>
      <v-btn type="submit" :color="mainColor" :loading="!hidePass && submitting" :disabled="hidePass">
        {{ $t('login.title') }}
      </v-btn>
    </div>
  </v-form>
</template>

<style scoped lang="scss">
.w-100.mb-6:disabled {
  margin-top: 0.4rem;
}
</style>
